<div class="content">
  <div class="d-flex justify-content-between mb-2">
    <button class="btn btn-primary" (click)="openAddUpliftModel(addUplift)">Add Uplift</button>
  </div>
  <div class="table-responsive bg-white mb-2">
    <table class="table border table-hover rounded" aria-describedby="default-uplift">
      <thead>
        <tr>
          <th>S.No</th>
          <th>User Name</th>
          <th>Description</th>
          <th>Tag</th>
          <th class="text-center">Position</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let uplift of uplifts; let i = index">
          <td>{{ (currentPage - 1) * limit + (i + 1) }}</td>
          <td>{{ uplift.user?.firstName || '' }} {{ uplift.user?.lastName || '' }}</td>
          <td class="ellipsis">{{ uplift?.post || '' }}</td>
          <td>{{ uplift?.tag || '' }}</td>
          <td class="text-center">{{ uplift?.serialNo || '' }}</td>
          <td>
            <span style="cursor: pointer; margin-right: 10px" (mouseup)="openEditUpliftModel(addUplift, uplift)">
              <img src="../../../assets/maunda/edit.svg" alt="edit" />
            </span>
            <span style="cursor: pointer; margin-right: 10px" (mouseup)="openDeleteConfirmationModel(delModel, uplift._id)">
              <img src="../../../assets/maunda/delete.svg" alt="delete" />
            </span>
            <span style="cursor: pointer" (click)="redirectToUpliftDetail(uplift._id)" (keydown)="redirectToUpliftDetail(uplift._id)">
              <img src="../../../assets/maunda/info-icon.svg" height="24" width="24" alt="info" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center" *ngIf="!uplifts.length && !loading">
    <span style="font-size: 25px; padding: 2rem">No Uplift Added Yet!</span>
  </div>

  <pagination
    *ngIf="uplifts.length && totalCount > limit"
    [boundaryLinks]="true"
    [totalItems]="totalCount"
    [maxSize]="3"
    [itemsPerPage]="limit"
    [(ngModel)]="currentPage"
    (pageChanged)="pageChanged($event)"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>

<ng-template #addUplift>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">{{ editUpliftId ? 'Update Uplift' : 'Add Uplift' }}</h2>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="upliftForm">
      <div class="form-group position-relative">
        <label class="form-label" for="userId">User:</label>
        <input id="userId" class="form-control" [(ngModel)]="searchKey" placeholder="Search user" (input)="onSearch()" [ngModelOptions]="{ standalone: true }" autocomplete="off" />
        <div id="search-results" class="dropdown-menu show" *ngIf="userList.length > 0">
          <div *ngFor="let user of userList" class="dropdown-item" (click)="selectUser(user)" (keydown)="selectUser(user)">
            <div style="display: grid">
              <span class="m-0 ms-2">{{ user.firstName }} {{ user.lastName }}</span>
              <span class="m-0 ms-2" style="font-size: 12px">{{ user.username }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="post">Description:<span style="color: red">*</span></label>
        <textarea id="post" class="form-control mb-3" cols="30" rows="5" formControlName="post" placeholder="Enter post description here" (blur)="extractUrl(); getPreview()" autocomplete="off"></textarea>
        <ng-container *ngIf="previewUrl && linkPreview">
          <a [href]="previewUrl" class="border d-inline-flex p-3 rounded" style="text-decoration: none; color: black" target="_blank">
            <img [src]="linkPreview.image" alt="" style="max-width: 50px; max-height: 50px; margin: auto" />
            <div>
              <p class="ms-2 m-0" style="font-size: 14px; padding: 10px 10px 0px 10px; line-height: normal">{{ linkPreview.title }}</p>
              <p class="ms-2 m-0" style="font-size: 12px; padding: 0px 10px; margin: 0px; line-height: normal">{{ linkPreview.description }}</p>
            </div>
          </a>
        </ng-container>
        <div *ngIf="submitted && f.post.errors" class="alert alert-danger">
          <span *ngIf="f.post.errors.required">Description is required</span>
          <span *ngIf="f.post.errors.maxlength">Description should be less than 1500 characters</span>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="tag">tag:<span style="color: red">*</span></label>
        <select id="tag" class="form-control" formControlName="tag">
          <option value="" hidden>Select tag</option>
          <option *ngFor="let item of ['Additional Resource', 'Noteable Uplift']" [value]="item">{{ item }}</option>
        </select>
        <div *ngIf="submitted && f.tag.errors" class="alert alert-danger">
          <span *ngIf="f.tag.errors.required">Post tag is required</span>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="serialNo">Post position:<span style="color: red">*</span></label>
        <input id="serialNo" type="number" class="form-control" formControlName="serialNo" placeholder="Set position for this post" min="1" autocomplete="off" />
        <div *ngIf="submitted && f.serialNo.errors" class="alert alert-danger">
          <span *ngIf="f.serialNo.errors.required">Post position is required</span>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-lightgray" (click)="cancelBtn()">Cancel</button>
    <button class="btn btn-green ms-2" (click)="editUpliftId ? updateUplift() : createUplift()">{{ editUpliftId ? 'Update' : 'Save' }}</button>
  </div>
</ng-template>

<ng-template #delModel>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">Delete Uplift</h2>
    </div>
  </div>
  <div class="modal-body text-center delete-modal">
    <img src="../../../assets/maunda/delete-img.svg" alt="" />
    <p>Are you sure you want to delete this uplift?</p>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-cancel" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-green ms-2" (click)="deleteUplift()">Confirm</button>
  </div>
</ng-template>
